<template>
  <uspCartao
    :fechavel="true"
    :fechado="true"
    titulo="Processos Juntados"
    @fechar="carregar"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando">
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
      </uspSkeleton>
      <div 
        v-if="lista && lista.length === 0 && !carregando"
        class="d-flex justify-content-center align-items-center flex-column"
      >
        <img 
          src="/imgs/usp-inbox.svg"
          alt="Ilustração de uma caixa vazia"
        >
        <p 
          class="font-weight-bold text-center mt-5 text-light"
        > 
          &nbsp;
          Não há arquivos processos juntados neste processo
        </p>
      </div>
      <div 
        v-if="lista && lista.length > 0 && !carregando"
        class="processos-juntados"
      >
        <div class="list-group">
          <div 
            v-for="processo in lista"
            :key="'juntado-'+processo.numpro"
            class="list-group-item processo-item"
          >
            <div class="conteudo">
              <div class="numpro">
                <span class="label">
                  {{ processo.numprojtdfmt }}
                </span>
              </div>
              <div class="item">
                <span class="label">Doc. Base</span>
                <span class="texto">{{ processo.titpro }}</span>
              </div>
              <div class="item">
                <span class="label">Interessado</span>
                <span class="texto">{{ processo.nominr }}</span>
              </div>
              <div class="item">
                <span class="label">Assunto</span>
                <span class="texto">{{ processo.codasu }} - {{ processo.dscasu }}</span>
              </div>
            </div>
            <div class="acoes">
              <router-link
                :to="gerarLinkRouter(processo)"
                target="_blank"
                class="btn btn-primary"
              >
                <em class="fas fa-folder-open" />
                Visualizar
              </router-link>
              <uspBotao 
                v-if="podeJuntar"
                variante="perigo"
                texto="Remover"
                icone="fas fa-trash"
                @clicar="togglerRemover(processo.numprojtd)"
              />
            </div>
            <form
              v-if="removerJuntado === processo.numprojtd"
              class="form-remover-juntado border border-secondary p-3 rounded"
              novalidate
              @submit.prevent="desjuntar(processo)"
            >
              <div class="form-row">
                <DespachoArea
                  v-model="txtdchrem"
                  label="Digite o motivo da remoção"
                  div-class="col-8"
                  input-id="juntada-txtdch"
                />
                <uspBotao 
                  type="submit"
                  variante="primario"
                  :carregando="removendo === processo.numprojtd"
                  text-carregando="Desjuntando..."
                  texto="Desjuntar"
                  icone="fas fa-check"
                  style="align-self: flex-end;"
                />
                <uspBotao 
                  type="reset"
                  variante="perigo"
                  texto="Cancelar"
                  icone="fas fa-ban"
                  style="align-self: flex-end;"
                  @clicar="togglerRemover()"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
    <template #rodape>
      <form
        v-if="!carregando && podeJuntar"
        class="form-juntado"
        novalidate
        @submit.prevent="salvar"
      >
        <uspAlerta
          v-if="erroForm"
          variante="perigo"
        >
          {{ erroForm }}
        </uspAlerta>
        
        <div class="form-row">
          <ProcessoInput
            v-model="numprojun"
            classe-div="col-2"
            input-id="juntar-numpro"
            input-name="numprojun"
            @change="buscar"
          />
          <DespachoArea
            v-model="txtdch"
            label=""
            div-class="col-8"
            input-id="juntada-txtdch"
          />
          <div class="acoes col-2">
            <button
              type="submit"
              class="btn btn-primary"
            >
              <span
                v-if="salvando"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <em 
                v-else
                class="fas fa-save" 
              />
              <span>&nbsp;&nbsp;{{ salvando ? 'Juntando...' : 'Juntar' }}</span>
            </button>
            <button
              type="reset"
              class="btn btn-danger"
              @click="limparForm()"
            >
              <em class="fas fa-ban" />
              <span class="sr-only">Cancelar</span>
            </button>
          </div>
          <div 
            v-if="item"
            class="form-row"
          >
            <div class="col-4">
              <span class="label">Doc. Base: </span>
              <span class="texto">{{ item.titpro }}</span>
            </div>
            <div class="col-4">
              <span class="label">Assunto: </span>
              <span class="texto">{{ item.assunto }}</span>
            </div>
            <div class="col-4">
              <span class="label">Interessado: </span>
              <span class="texto">{{ item.nominr }}</span>
            </div>
            <div class="col-12">
              <span class="label">Complemento: </span>
              <span class="texto">{{ item.cplasu || '' }}</span>
            </div>
          </div>
        </div>
      </form>
    </template>
  </uspCartao>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '../store/types';
import { desformatarProcesso } from '@/utils/Formatador'
import ProcessoInput from '../components/inputs/ProcessoInput.vue';
import DespachoArea from './inputs/DespachoArea.vue'
import ProcessosServico from '../dominio/ProcessosServico';

export default {
    name: 'ProcessosCartaoJuntados',
    components: { DespachoArea, ProcessoInput },
    props: {
        numpro: {
            type: String,
            required: true
        },
    },
    data(){
      return {
        api: ProcessosServico.build({}),
        carregando: false,
        carregandoItem: false,
        erroForm: '',
        item: null,
        lista: null,
        numprojun: '',
        removerJuntado: '',
        removendo: false,
        salvando: false,
        txtdch: '',
        txtdchrem: ''
      }
    },
    computed: {
      ...mapGetters({
        permissoes: Getters.PROCESSOS.ACOES
      }),
      podeJuntar(){
        return this.permissoes?.juntar === 'S'
      }
    },
    methods: {
      ...mapActions({
        carregarAndamentos: Actions.ANDAMENTOS.LISTAR,
      }),
      buscar(){
        const self = this
        if(!self.numprojun){
          self.limparForm()
          return
        }
        self.carregandoItem = true
        self.api.obter(desformatarProcesso(self.numprojun))
          .then(item => self.item = item)
          .catch(error => {
            self.limparForm()
            self.erroForm = error.erro
          })
          .finally(() => self.carregandoItem = false)
      },
      gerarLinkRouter(processo){
        return processo.tippro === 'D' ? 
          `/processos/processos-digitais/${processo.numprojtd}` : 
          `/servicos/processos/obterAndamento?numpro=${processo.numprojtd}`
      },
      carregar(status){
        const self = this
        if(status.exibir){
          self.carregando = true
          this.api.listarJuntados(self.numpro)
            .then(lista => self.lista = lista)
            .catch(error => self.error = error)
            .finally(() => self.carregando = false)
        }
      },
      desjuntar(processo){
        const self = this
        if(!self.txtdchrem){
          alert('Texto sobre remoção é obrigatório!')
          return
        }
        self.removendo = processo.numprojtd
        self.api.desjuntarProcesso(
          self.numpro,
          processo.numprojtd,
          self.txtdchrem
        ).then(() => {
          self.carregar({ exibir: true })
          self.carregarAndamentos({ numpro: self.numpro })
        })
        .catch(error => alert(error.erro))
        .finally(() => self.removendo = false)
      },
      togglerRemover(numprojtd){
        const self = this
        self.txtdchrem = ''
        self.removerJuntado = numprojtd
      },
      salvar(){
        const self = this
        if(!self.numprojun){
          self.erroForm = 'Número de processo precisa ser válido!'
          return
        }
        self.salvando = true
        self.api.juntarProcesso(
          self.numpro,
          desformatarProcesso(self.numprojun),
          self.txtdch
        )
        .then(() => {
          self.carregar({ exibir: true })
          self.carregarAndamentos({ numpro: self.numpro })
        })
        .catch(error => self.erroForm = error.erro)
        .finally(() => self.salvando = false)
      },
      limparForm(){
        const self = this
        self.item = null
        self.erroForm = ''
        self.numprojun = ''
      }
    },
}
</script>

<style lang="scss" scoped>
    
    div.processo-item {
      align-items: center;
      display: flex;
      flex-flow: row wrap;

      div.conteudo {
        display: flex;
        flex-flow: row wrap;
        flex: 0 0 75%;

        div.numpro {
          width: 100%;
          text-align: center;
        }
        div.item {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          flex: 0 0 33%;
        }
      }

      div.acoes {
        display: flex;
        flex-flow: column;
        flex: 1 0 10em;
        align-items: stretch;
      }

      form.form-remover-juntado {
        width: 100%;
      }
    }

    span.label {
        color: #1094ab;
        font-weight: 900;
    }

    form.form-juntado {
      div.form-row {
        align-items: center;

        div.acoes {
          align-items: center;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;

          em.fas {
            margin: 0
          }

        }
      }
    }

</style>