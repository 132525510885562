<template>
  <uspModal 
    :ref="ref"
    titulo="Adicionar Assinatura"
    max-width="760px"
    :mostrar="abrirModal"
    @fechar="fechar()"
  >
    <template #body>
      <uspAlerta
        v-if="erro"
        variante="perigo"
        :fechavel="true"
        @fechar="erro = ''"
      > 
        {{ erro }}
      </uspAlerta>
      <form
        v-if="ocorrencia"
        @submit.prevent=""
      >
        <div class="form-group row px-3">
          <span
            id="assinar-documento"
            class="col-sm-2 label"
          >
            Documento
          </span>
          <span 
            class="input-span col-sm-10"
            aria-labelledby="assinar-documento"
          >
            {{ documento }}
          </span>
        </div>
        <div class="form-group row px-3">
          <label 
            for="assinar-tipasi" 
            class="col-sm-2"
          >
            Tipo Assinatura
          </label>
          <select 
            id="assinar-tipasi"
            v-model="tipasi"
            class="form-control col-sm-10"
            name="tipasi"
          >
            <option value="">
              - Selecione -
            </option>
            <option value="U">
              ID Digital
            </option>
          </select>
        </div>
        <fieldset>
          <legend>Participantes</legend>
          <div 
            v-if="participantes.length === 0" 
            class="participante"
          >
            Nenhum participante adicionado!
          </div>
          <div 
            v-for="(p, idx) in participantes"
            :key="'participante_'+idx"
            class="form-row participante"
          >
            <span class="texto texto-nusp">{{ p.codpes }}</span>
            <span class="texto texto-nome">{{ p.nompes }}</span>
            <button 
              type="button"
              class="btn btn-danger align-self-center col-md-2"
              @click="removerParticipante(idx)"
            >
              <em class="fas fa-times" />
              Remover
            </button>
          </div>
          <div class="form-row mt-4 adicionar-participante">
            <input
              id="participante-codpes"
              v-model="codpes"
              type="number"
              name="codpes"
              class="form-control col-md-3"
              @keyup.enter="obterNomePessoa(codpes, true)"
              @change="obterNomePessoa(codpes, false)"
            >
            <span 
              class="nome-pessoa col-md-6"
              :class="{ 'invalido': !!erroBusca }"
            >
              {{ nome }}
            </span>
            <div class="acoes col-md-3">
              <uspBotao 
                type="button"
                variante="primario"
                tamanho="pq"
                texto="Adicionar"
                :carregando="buscandoPessoa"
                texto-carregando="Buscando..."
                icone="fas fa-plus"
                @clicar="adicionarParticipante"
              />
            </div>
          </div>
        </fieldset>
      </form>
    </template>
    <template #footer>
      <uspBotao
        type="button"
        variante="sucesso"
        texto="Salvar"
        texto-carregando="Salvando"
        :carregando="salvando"
        icone="fas fa-save"
        @clicar="salvar()"
      />
      <uspBotao
        type="button"
        variante="perigo"
        texto="Fechar"
        icone="fas fa-times"
        @clicar="fechar()"
      />
    </template>
  </uspModal>
</template>

<script>
import ComumServico from '@/portal/dominio/comum/ComumServico.js'
import { Actions, Getters, Mutations } from '@/processos/store/types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {

    name: 'ProcessosModalAssinar',

    data(){
        return {
            buscandoPessoa: false,
            codpes: null,
            numpro: this.$route.params.numpro,
            erro: '',
            erroBusca: '',
            comumApi: ComumServico.build({}),
            ref: 'assinaturaModal',
            salvando: false,
            tipasi: 'U',
            participantes: [],
            vazio: 'Digite um NUSP válido para buscar...',
        }
    },

    computed: {
        ...mapGetters({
            modal: Getters.ANDAMENTOS.ASSINATURA,
        }),
        abrirModal(){
            return this.modal.mostrar
        },
        documento(){
            return this.ocorrencia.getDescricaoArquivo ? this.ocorrencia.getDescricaoArquivo() : ''
        },
        ocorrencia(){
            return this.modal.ocorrencia
        },
        nome(){
          if (this.buscandoPessoa) return 'Buscando dados do NUSP...';
          else if(this.erroBusca) return this.erroBusca;
          else if(!this.codpes) return this.vazio;
          else return this.nompes;
        }

    },

    methods: {
        ...mapActions({
            assinar: Actions.ANDAMENTOS.ASSINAR
        }),
        ...mapMutations({
            setAssinar: Mutations.ANDAMENTOS.ASSINATURA
        }),
        adicionarParticipante(){
          const self = this;
          self.erroBusca = '';
          if(!self.codpes){
            self.erroBusca = self.vazio;
            self.codpes = '';
            self.nompes = '';
            return;
          }
          if(self.participantes.find(p => p.codpes == self.codpes)){
            self.erro = `Participante ${self.codpes} já adicionado!`;
            self.codpes = '';
            self.nompes = '';
            return;
          }
          self.participantes.push({ codpes: self.codpes, nompes: self.nompes });
          self.codpes = '';
          self.nompes = '';
        },
        fechar(){
          this.erro = '';
          this.erroBusca = '';
          this.buscandoPessoa = false;
          this.participantes = [];
          this.codpes = '';
          this.nompes = '';
          this.setAssinar()
        },
        obterNomePessoa(codpes, adicionar){
            const self = this
            self.buscandoPessoa = true;
            self.erroBusca = '';
            self.comumApi.obterPessoa({ codpes })
                .then(pessoa =>{
                  if(pessoa?.nompes){
                    self.nompes = pessoa.nompes;
                    if(adicionar) self.adicionarParticipante();
                  } else {
                    self.nompes = ''
                    self.codpes = ''
                    self.erroBusca = `${codpes} não encontrado!`
                  }
                })
                .catch(erro => {
                  self.nompes = ''
                  self.codpes = ''
                  self.erroBusca = erro || `${codpes} não encontrado!`
                })
                .finally(() => self.buscandoPessoa = false)
        },
        removerParticipante(indice){
            console.log('removção')
            this.participantes.splice(indice, 1)
        },
        salvar(){
            const self = this
            const validos = self.participantes.filter(p => !!p.codpes)
            if(validos.length == 0){
                self.error = 'Ao menos uma pessoa válida deve ser cadastrada!'
                return
            }
            if(!self.tipasi){
                self.error = 'Tipo de assinatura é obrigatório!'
                return
            }
            self.salvando = true
            self.assinar({ 
                numpro: self.numpro,
                tipasi: self.tipasi,
                codarqpdl: self.ocorrencia.arquivo.codarqpdl,
                codpesasi: validos.map(p => p.codpes).join(','),
                numand: self.ocorrencia.numand, 
                numseqocoand: self.ocorrencia.numseqocoand
             }).then(() => self.fechar())
               .catch(error => self.erro = error.erro)
               .finally(() => self.salvando = false)
        }
    },

}
</script>

<style lang="scss" scoped>

    .form-control-plaintext {
        font-weight: bold;
        color: #000
    }

    #assinar-tipasi {
      align-self: center;
    }

    span.input-span {
      font-weight: bold;
      align-self: center;
    }

    div.participante {
        border: 0.8px solid #696969;
        background-color: rgba(0, 0, 0, .10);
        padding: 0.5em;
        margin-bottom: -.8px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        span.texto {
          padding: 0 1rem;
          font-weight: bold;
        }
    }

    ::v-deep label, span.label, span.titulo, legend {
        font-weight: bold;
        font-size: 100%;
        color: #1094ab
    }

    div.adicionar-participante {
      align-items: center;
      background-color: #f7f7f7;
      padding: 0 .5rem;

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
      }

      span.nome-pessoa {
        text-align: center;
      }

      div.acoes {
          padding: .5em;
          text-align: right;
      }
    }

</style>