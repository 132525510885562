<template>
  <div 
    class="thumb" 
    :title="nomeArquivo"
    @click="abrirArquivo"
  >
    <img
      v-if="!statusCarregando" 
      :src="urlTemporario" 
      alt="thumbnail do arquivo" 
    >
    <div 
      v-if="!statusCarregando"
      class="tag"
    >
      <em :class="['icone', 'fas', icone]" />
      <span class="nome">
        {{ nomeArquivo }}
      </span>
    </div>
    <div 
      v-else
      class="loading"
    >
      <uspSpinner 
        formato="border"
        style="width: 5em; height: 5em;" 
      />
      Anexando documento, por favor aguarde...
    </div>
  </div>
</template>
  
<script>
import Arquivo from '../dominio/modelos/Arquivo';
import Temporario from '../dominio/modelos/Temporario';

export default {

    name: 'ProcessosArquivoThumbnail',

    props: {
        arquivo: {
            type: Arquivo,
            default: null
        },
        base64: {
            type: String,
            default: ''
        },
        carregando: {
            type: Boolean,
            default: true
        },
        temporario: {
            type: Temporario,
            default: null
        },
        numpro: {
            type: String,
            default: ''
        }
    },

    data(){
        return {
            //numpro: this.$route.params.numpro
        }
    },

    computed: {
        icone(){
            return 'fa-file-pdf'
        },
        nomeArquivo(){
            if(this.arquivo) return this.arquivo.nomarq
            else if(this.temporario) return this.temporario.nomarqtmp
            else if(this.base64) return 'Arquivo Gerado'
            else return ''
        },
        statusCarregando(){
            return !this.arquivo?.codarqpdl && !this.temporario?.codarqtmp && !this.base64
        },
        urlTemporario() {
            let url = ''
            if(this.arquivo){
                url = `/proxy/wsprocesso/api/processo/${this.$route.params.numpro}/anexo/${this.arquivo.codarqpdl}/preview`
            } else if(this.temporario){
                url = `/proxy/wsprocesso/api/arquivo/temp/${this.temporario.codarqtmp}/preview?width=300`
            } else if(this.base64){
                url = this.base64
            }
            return url
        }
    },

    methods: {
        abrirArquivo(){
            if(this.base64){
                this.$emit('abrir')
                return
            }
            const baseUrl = `${window.location.origin}/proxy/wsprocesso/api`
            const url = this.arquivo ? `${baseUrl}/processo/${this.numpro}/anexo/${this.arquivo.codarqpdl}?modoView=inline`
                                     : `${baseUrl}/arquivo/temp/${this.temporario.codarqtmp}?modoView=inline`
            window.open(url, '_blank')
        }
    },
    
}
</script>

<style lang="scss" scoped>
    div.thumb {
        cursor: pointer;
        position: relative;
        border: 1px solid #CECECE;
        border-radius: 0.75em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3em;
        height: 425px;
        width: 300px;

        img {
            width: 100%;
            max-height: 100%;
        }

        div.loading {
            align-items: center;
            align-self: stretch;
            display: flex;
            flex-flow: column;
            height: 100%;
            justify-content: center;
            text-align: center;
        }

        div.tag {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            border-top: 1px solid #CECECE;
            background-color: #FFF;
            border-bottom-left-radius: 0.75em;
            border-bottom-right-radius: 0.75em;
            padding: 5px;
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            color: black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            i.icone {
                margin: 0 0.8em
            }
        }

        
    }

    div.thumb:hover {
        border-color: #1094ab;
        div.tag {
            color: #1094ab;
        }

    }

</style>