<template>
  <uspModal 
    titulo="Cancelar Saída em Processo"
    :mostrar="mostrar"
    @fechar="fechar()"
  >
    <template #body>
      <usp-alerta 
        v-if="erro"
        variante="perigo"
        :fechavel="true"
        @fechar="erro = ''"
      >
        {{ erro }}
      </usp-alerta>
      <form
        @submit.prevent=""
      >
        <DespachoArea
          v-model="dados.txtdch"
          placeholder="Digite o motivo do cancelamento..."
          label=""
          input-id="cancelar-saida-txtdch"
        />
      </form>
    </template>
    <template #footer>
      <uspBotao 
        type="button"
        variante="sucesso"
        texto="Salvar"
        texto-carregando="Salvando"
        :carregando="salvando"
        icone="fas fa-save"
        @clicar="salvar()"
      />
      <uspBotao 
        type="button"
        variante="perigo"
        texto="Fechar"
        icone="fas fa-times"
        @clicar="fechar()"
      />
    </template>
  </uspModal>
</template>

<script>
import { Actions } from '@/processos/store/types'
import { mapActions } from 'vuex'
import DespachoArea from '../inputs/DespachoArea.vue'
export default {
    name: 'ProcessosModalCancelarSaida',
    components: { DespachoArea },
    props:{
        mostrar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            numpro: this.$route.params.numpro,
            erro: '',
            salvando: false,
            dados:{
              txtdch: ''
            }
        };
    },
    methods: {
        ...mapActions({
          cancelarSaida: Actions.PROCESSOS.CANCELAR_SAIDA,
        }),
        fechar(){
            this.dados = {
              txtdch: '',
            }
            this.$emit('fechar')
        },
        salvar() {
            const self = this;
            self.salvando = true
            self.cancelarSaida({ numpro: self.numpro, dados: self.dados })
                .then(() => self.fechar())
                .catch((error) => self.erro = error.erro)
                .finally(() => self.salvando = false)
        }
    },
}
</script>