<template>
  <form @submit.prevent="">
    <uspAlerta 
      v-if="aviso"
      :variante="aviso.tipo"
      fechavel
      @fechar="aviso = null"
    >
      {{ aviso.mensagem }}
    </uspAlerta>
    <div class="form-row justify-content-center">
      <ProcessosArquivoThumbnail 
        v-show="ocorrencia.temporario || ocorrencia.arquivo || imagem"
        :temporario="ocorrencia.temporario"
        :arquivo="ocorrencia.arquivo" 
        :base64="imagem"
        :numpro="numpro"
        @abrir="baixar"
      />
      <div class="col-lg conteudo-form">
        <div class="form-row form-group">
          <label 
            for="titulo"
            class="col-form-label col-lg-2"
          >
            Título
          </label>
          <div class="col-lg-10">
            <input 
              id="titulo"
              v-model="titulo"
              name="titulo"
              class="form-control"
            >
          </div>
        </div>
        <div class="form-row form-group">
          <ckeditor 
            v-model="texto" 
            :config="editorConfig"
            class="ckeditor-div"
          />
        </div>
        <div 
          v-if="ocorrencia.podeEditar()"
          class="form-row acoes"
        >
          <uspBotao 
            type="button"
            tamanho="pq"
            variante="perigo"
            icone="fa fa-trash"
            texto="Remover"
            @clicar="remover()"
          />
          <uspBotao 
            type="button"
            tamanho="pq"
            icone="fa fa-file-pdf"
            texto="Visualizar"
            :carregando="visualizando"
            texto-carregando="Gerando"
            @clicar="visualizar()"
          />
          <uspBotao 
            type="button"
            tamanho="pq"
            variante="sucesso"
            icone="fa fa-paperclip"
            texto="Anexar"
            texto-carregando="Salvando"
            :carregando="salvando"
            @clicar="salvar()"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Ocorrencia from '@/processos/dominio/modelos/Ocorrencia';
import ProcessosArquivoThumbnail from '../ProcessosArquivoThumbnail.vue';
import { mapActions, mapMutations } from 'vuex';
import { Actions, Mutations } from '@/processos/store/types';


export default {
    name: 'ProcessosOcorrenciaGerarForm',
    components: { ProcessosArquivoThumbnail },
    props: {
        indice: {
            type: String,
            required: true
        },
        ocorrencia: {
            type: Ocorrencia,
            required: true
        },
        numpro: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            aviso: null,
            salvando: false,
            imagem: '',
            visualizando: false,
            texto: "",
            titulo: "",
            editorConfig: {
                removeButtons: 'Anchor,HorizontalRule,Image,Table',
                coreStyles_bold: { element: 'b', overrides: 'strong' },
                coreStyles_italic: { element: 'i', overrides: 'em' },
                coreStyles_strike: {
                  element: 'font',
                  attributes: { 'font style' :'text-decoration: line-through' },
                  overrides: 'strike'
                }
            },
            
        };
    },
    methods: {
        ...mapActions({
            gerar: Actions.ANDAMENTOS.GERAR_ANEXO,
            removerNovaOcorrencia: Actions.ANDAMENTOS.REMOVER_NOVA_OCORRENCIA,
        }),
        ...mapMutations({
            setConfirmar: Mutations.PROCESSOS.CONFIRMAR
        }),
        baixar(){
            const self = this
            self.gerar({ documento: { titulo: self.titulo, texto: self.texto, acao: 'pdf' }, ocorrencia: self.ocorrencia })
                .then(pdf => URL.createObjectURL(pdf))
                .then(url => {
                    let link = document.createElement("a");
                    link.href = url;
                    link.download = "preview de documento.pdf";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => self.aviso = { tipo: 'perigo', mensagem: error.erro })
                .finally(() => self.visualizando = false)
        },
        lerImagem(imagem){
            const self = this
            const reader  = new FileReader();
            reader.addEventListener("load", function () {
                self.imagem = reader.result
            }, false);

            reader.onerror = (error) => console.error(error)
            reader.readAsDataURL(imagem);
        },
        remover(){
            this.setConfirmar({
                titulo: 'Remoção da geração de documento #'+this.indice,
                mensagem: `Deseja mesmo remover a geração de documento #${this.indice}?`,
                atencao: true,
                abrir: true,
                acao: this.removerNovaOcorrencia,
                dados: this.ocorrencia,
            })
        },
        salvar() {
            const self = this
            if(self.validar()){
                self.salvando = true
                self.gerar({ documento: { titulo: self.titulo, texto: self.texto, acao: 'salvar' }, ocorrencia: self.ocorrencia })
                    .catch(error => self.aviso = { tipo: 'perigo', mensagem: error.erro })
                    .finally(() => self.salvando = false)
            }
        },
        validar(){
            if(!this.titulo){
                this.aviso = {
                    tipo: 'perigo',
                    mensagem: 'O campo de título é obrigatório!'
                }   
                return false
            }
            if(!this.texto){
                this.aviso = {
                    tipo: 'perigo',
                    mensagem: 'O campo de conteúdo é obrigatório!'
                }   
                return false
            }
            return true
        },
        visualizar(){
            const self = this
            if(self.validar()){
                self.visualizando = true
                self.gerar({ documento: { titulo: self.titulo, texto: self.texto, acao: 'jpg' }, ocorrencia: self.ocorrencia })
                    .then(imagem => self.lerImagem(imagem))
                    .catch(error => self.aviso = { tipo: 'perigo', mensagem: error.erro })
                    .finally(() => self.visualizando = false)
            }
        }
    }
}
</script>

<style>

    div.ckeditor-div {
        width: 100%
    }

    div.acoes {
        justify-content: flex-end;
    }

    div.conteudo-form > div {
        padding-left: 0.7em;
    }

</style>