<template>
  <uspModal 
    titulo="Dar Saída em Processo"
    :mostrar="mostrar"
    @fechar="fechar()"
  >
    <template #body>
      <usp-alerta 
        v-if="erro"
        variante="perigo"
        :fechavel="true"
        @fechar="erro = ''"
      >
        {{ erro }}
      </usp-alerta>
      <div
        class="btn-group btn-group-sm btn-group-toggle mb-4 botoes"
        data-toggle="buttons"
      >
        <label
          class="btn btn-sm btn-primary"
          :class="{ active: estacao }"
          @click="estacao = true"
        >
          <input 
            type="radio"
            name="options"
          > Estação
        </label>
        <label
          class="btn btn-sm btn-primary"
          :class="{ active: !estacao }"
          @click="estacao = false"
        >
          <input
            type="radio"
            name="options"
          > Pessoa
        </label>
      </div>
      <form
        :class="{ 'was-validated': validado }"
        novalidate
        @submit.prevent="salvar"
      >
        <div 
          v-show="estacao"
          class="form-group"
        >
          <UnidadeSelect 
            v-model="codund"
            :erro-validacao="erros.codund"
            :obrigatorio="true"
            input-id="saida-codund" 
          />
          <EstacaoSelect 
            v-model="sgletadst"
            :erro-validacao="erros.sgletadst"
            :obrigatorio="true"
            :tipo="'UNIDADE'"
            input-id="saida-sgleta"
            @change="listarMembros"
          />
          <div class="form-group">
            <label for="saida-codpesmem">
              Membro da Estação <small>(opcional)</small>
            </label>
            <select
              id="saida-codpesmem"
              v-model="codpesmem"
              name="codpesmem"
              class="form-control"
            >
              <option v-if="membros.length === 0"> 
                - Selecione a estação primeiro - 
              </option>
              <option v-else> 
                - Selecione o membro - 
              </option>
              <option
                v-for="membro in membros"
                :key="'membro-'+membro.codpes"
                :value="membro.codpes"
              >
                {{ membro.codpes }} - {{ membro.nompes }}
              </option>
            </select>
          </div>
        </div>
        <div 
          v-show="!estacao"
          class="form-group"
        >
          <UspNusp
            v-model="codpesdst"
            label="Nº USP"
            input-id="saida-codpesdst"
            :erro-validacao="erros.codpesdst"
            :obrigatorio="!estacao"
          />
          <div class="form-group">
            <label for="saida-sgletamem">
              Estação do Usuário
            </label>
            <select
              id="saida-sgletamem"
              v-model="sgletamem"
              name="sgletamem"
              class="form-control"
              :required="!estacao"
            >
              <option v-if="estacoes.length === 0"> 
                - Escolha primeiro a pessoa - 
              </option>
              <option
                v-for="est in estacoes"
                :key="'estacao-'+est.sgleta"
                :value="est.sgleta"
              >
                {{ est.sgleta }} - {{ est.nometa }}
              </option>
            </select>
            <span class="invalid-feedback">{{ erros.sgletamem }}</span>
          </div>
        </div>
        <DespachoArea
          v-model="txtdch"
          input-id="saida-txtdch"
        />
      </form>
    </template>
    <template #footer>
      <uspBotao 
        type="button"
        variante="sucesso"
        texto="Salvar"
        texto-carregando="Salvando"
        :carregando="salvando"
        icone="fas fa-save"
        @clicar="salvar()"
      />
      <uspBotao 
        type="button"
        variante="perigo"
        texto="Fechar"
        icone="fas fa-times"
        @clicar="fechar()"
      />
    </template>
  </uspModal>
</template>

<script>
import { Actions, Getters, Mutations } from '@/processos/store/types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import UnidadeSelect from '../inputs/UnidadeSelect.vue'
import EstacaoSelect from '../inputs/EstacaoSelect.vue'
import DespachoArea from '../inputs/DespachoArea.vue'
export default {
    name: 'ProcessosModalSaida',
    components: { UnidadeSelect, EstacaoSelect, DespachoArea },
    props:{
        mostrar: {
            type: Boolean,
            default: false
        }
    },
    data() {
      return {
        carregandoMembros: false,
        membros: [],
        numpro: this.$route.params.numpro,
        erro: '',
        erros: {},
        estacao: true,
        validado: false,
        salvando: false,
        codund: '',
        codpesmem: '',
        codpesdst: '',
        txtdch: '',
        sgletadst: '',
        sgletamem: ''
      };
    },
    computed: {
      ...mapGetters({
        estacoes: Getters.ESTRUTURA.ESTACOES_PESSOA
      })
    },
    watch: {
      codpesdst(val){
        const self = this;
        if(val){
          self.carregarEstacoesPessoa({ codpes: val })
            .then(lista => self.sgletamem = lista[0].sgleta)
        }
      }
    },
    methods: {
        ...mapActions({
            darSaida: Actions.PROCESSOS.DAR_SAIDA,
            carregarEstacoesPessoa: Actions.ESTRUTURA.ESTACOES_PESSOA,
            carregarMembros: Actions.ESTRUTURA.MEMBROS
        }),
        ...mapMutations({
          setEstacoes: Mutations.ESTRUTURA.ESTACOES_PESSOA
        }),
        listarMembros(sgletadst){
          const self = this
          console.log(sgletadst)
          if(sgletadst && self.estacao){
            self.carregandoMembros = true
            self.carregarMembros({ sgleta: sgletadst })
              .then(lista => self.membros = lista)
              .finally(() => self.carregandoMembros = false)
          }
        },
        fechar(){
          this.codund = ''
          this.txtdch = ''
          this.sgletadst =  ''
          this.sgletamem =  ''
          this.codpesdst =  ''
          this.codpesmem =  ''
          this.setEstacoes([])
          this.membros = []
          this.$emit('fechar')
        },
        async salvar() {
            const self = this;
            if(self.validar()){
              const dados = self.estacao ? 
                            { codund: self.codund, codpesdst: self.codpesmem, sgletadst: self.sgletadst, txtdch: self.txtdch } :
                            { codpesdst: self.codpesdst, sgletadst: self.sgletamem, txtdch: self.txtdch }
              self.salvando = true;
              self.darSaida({ numpro: self.numpro, dados })
                  .then(() => self.fechar())
                  .catch((error) => self.erro = error.erro)
                  .finally(() => self.salvando = false)
            }
        },
        validar(){
          const self = this;
          const erros = {};
          self.validado = true;

          if(self.estacao){
            if(!self.codund){
              erros.codund = 'Unidade é de preenchimento obrigatório!';
            }
            if(!self.sgletadst){
              erros.sgletadst = 'Estação de destino é de preenchimento obrigatório!';
            }
          } else {
            if(!self.codpesdst){
              erros.codpesdst = 'NUSP de destino é de preenchimento obrigatório!';
            }
            if(!self.sgletamem){
              erros.sgletamem = 'Estação do usuário escolhido é de preenchimento obrigatório!';
            }
          } 

          self.erros = erros;
          return Object.keys(erros).length === 0;
        }
    },
}
</script>
<style scoped lang="scss">
  /* Botões */
  .btn-group.botoes {
    display: flex;
    justify-content: center;

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

</style>