<template>
  <uspCartao
    :fechavel="false"
    titulo="Andamentos"
  >
    <template #corpo>
      <uspAlerta
        v-if="aviso"
        variante="sucesso"
        :fechavel="true"
        @fechar="limparAviso('')"
      >
        {{ aviso }}
      </uspAlerta>
      <uspAlerta
        v-if="erros.length > 0"
        variante="perigo"
        fechavel
        @fechar="limparErros([])"
      >
        Erro ao anexar arquivos:
        <ul>
          <li 
            v-for="(erro, idx) in erros"
            :key="'erro-anexo-'+idx"
          >
            {{ erro.mensagem || erro.erro || erro }}
          </li>
        </ul>
      </uspAlerta>
      <uspSkeleton v-if="carregando" />
      <div 
        v-else
        class="usp-resultados"
      >
        <ul class="list-group">
          <li 
            v-for="(andamento, idx) in andamentosReverso"
            :key="andamento.numand"
            class="list-group-item andamento-item"
          >
            <ProcessosAndamentoItem 
              :numpro="numpro"
              :andamento="andamento"
              :editavel="idx === 0 && getAcao('andamento') === 'S'" 
            />
          </li>
        </ul>
      </div>
    </template>
  </uspCartao>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Actions, Getters, Mutations } from '@/processos/store/types'
import ProcessosAndamentoItem from './ProcessosAndamentoItem.vue';
export default {
    name: 'ProcessosCartaoAndamentos',
    components: { ProcessosAndamentoItem },
    props: {
        numpro: {
            type: String,
            required: true
        },
    },
    computed: {
        ...mapGetters({
            aviso: Getters.PROCESSOS.ACAO_AVISO,
            andamentos: Getters.ANDAMENTOS.LISTA,
            carregando: Getters.ANDAMENTOS.CARREGANDO,
            erros: Getters.ANDAMENTOS.ERROS,
            getAcao: Getters.PROCESSOS.ACAO,
        }),
        andamentosReverso(){
            return this.andamentos.slice().reverse()
        }
    },
    mounted() {
        this.listarTiposConf()
        this.carregar({ numpro: this.numpro });
    },
    methods: {
        ...mapActions({
            carregar: Actions.ANDAMENTOS.LISTAR,
            listarTiposConf: Actions.ESTRUTURA.TIPO_CONFERENCIA
        }),
        ...mapMutations({
          limparErros: Mutations.ANDAMENTOS.ERROS,
          limparAviso: Mutations.PROCESSOS.ACAO_AVISO
        })
    },
}
</script>

<style lang="scss" scoped>

    strong.label {
        color: #1094ab
    }

    .usp-resultados {

        ::v-deep .andamento-item {
            padding: 0;
        }

        ::v-deep .andamento-item:last-child div.linha.aberto .ocorrencia-item:last-child {
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        }

    }
</style>