<template>
  <div class="item-leitura">
    <div 
      v-if="ocorrencia.tipo.codetroco === 'ocoAnexacao'"
      class="arquivo"
    >
      <div class="informacoes">
        <div>
          <span class="titulo">Tipo: </span>
          <span class="subtitulo">{{ ocorrencia.arquivo.dscetrdoc }}</span>
        </div>
        <div>
          <span class="titulo">Arquivo: </span>
          <span class="subtitulo">{{ ocorrencia.arquivo.getDescricao() }}</span>
        </div>
        <div>
          <span class="titulo">Tipo de Conferência: </span>
          <span class="subtitulo">{{ ocorrencia.arquivo.dscetrcfc }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="ocorrencia.txtdch"
      class="informacao subtitulo" 
    >
      <span class="titulo">{{ ocorrencia.tipo.getTipdch() }}: </span>
      <span style="white-space: break-spaces;">{{ ocorrencia.txtdch }}</span>
    </div>
    <div class="acoes">
      <usp-botao 
        v-if="ocorrencia.tipo.codetroco === 'ocoAnexacao'"
        type="button"
        tamanho="pq"
        icone="fas fa-file"
        texto="Visualizar Arquivo"
        @clicar="visualizar()"
      />
    </div>
  </div>
</template>

<script>
import Ocorrencia from '@/processos/dominio/modelos/Ocorrencia';

export default {

    name: 'ProcessoOcorrenciaDisplayForm',

    props: {
        ocorrencia: {
            type: Ocorrencia,
            required: true
        },
        numpro: {
            type: String,
            default: ''
        }
    },

    methods:{
        visualizar(){
            const baseUrl = `${window.location.origin}/proxy/wsprocesso/api`
            const url = `${baseUrl}/processo/${this.numpro}/anexo/${this.ocorrencia.arquivo.codarqpdl}?modoView=inline`
            window.open(url, '_blank')
        }
    },

}
</script>

<style lang="scss" scoped>

    div.item-leitura {
        width: 100%;
        padding: 0.5em 1.75em;
    }

    div.informacao {
        flex-basis: 100% !important;
    }

    div.informacoes {
        flex: 1;
        margin-right: auto;
    }

    div.acoes {
        text-align: right
    }

</style>