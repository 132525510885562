<template>
  <PortalCardPaginado
    :acionavel="true"
    :fechado="true"
    titulo="Árvore de Arquivos"
    :lista="lista"
    :criador-link-acionavel="criarLinkArquivo"
  >
    <template #corpo>
      <form 
        novalidate
        @submit.prevent="buscar"
      >
        <div class="input-group form-group">
          <input
            id="busca"
            v-model="busca"
            type="search"
            name="busca"
            class="form-control"
            placeholder="Busque por tipo, nome ou conteúdo de arquivo..."
            @input="!busca ? limpar() : null"
          >
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="submit"
            >
              <em class="fas fa-search" />
              Buscar
            </button>
            <button 
              v-if="buscado"
              class="btn btn-outline-danger" 
              type="button"
              @click="limpar"
            >
              Limpar
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg form-group">
            <label 
              for="codetrdoc"
              class="sr-only"
            >
              Tipo de Documento
            </label>
            <select 
              :id="'codetrdoc'"
              v-model="codetrdoc"
              class="form-control"
              :name="'codetrdoc'"
            >
              <option value="">
                Filtre por tipo de documento...
              </option>
              <option
                v-for="tipo in tiposDoc"
                :key="tipo.codetrvlrpdl"
                :value="tipo.codetrvlrpdl"
              >
                {{ tipo.dscetrvlrpdl }}
              </option>
            </select>
          </div>
          <div class="col-lg form-group">
            <label 
              for="codetrcfc"
              class="sr-only"
            >
              Tipo de Conferência
            </label>
            <select
              id="codetrcfc"
              v-model="codetrcfc"
              name="codetrcfc"
              class="form-control"
              required
            >
              <option value="">
                Filtre pelo tipo de conferência...
              </option>
              <option
                v-for="tipo in tiposConf"
                :key="tipo.codetrvlrpdl"
                :value="tipo.codetrvlrpdl"
              >
                {{ tipo.dscetrvlrpdl }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <uspSkeleton v-if="buscando">
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
      </uspSkeleton>
      <div 
        v-if="lista.length === 0 && !buscando"
        class="d-flex justify-content-center align-items-center flex-column"
      >
        <img 
          src="/imgs/usp-inbox.svg"
          alt="Ilustração de uma caixa vazia"
        >
        <p 
          class="font-weight-bold text-center mt-5 text-light"
        > 
          &nbsp;
          Não há arquivos neste processo com esses critérios
        </p>
      </div>
    </template>
    <template #default="conteudo">
      <div 
        v-show="!buscando"
        class="row linha-arvore"
      >
        <div class="col-md ident">
          {{ conteudo.item.ident }}
        </div>
        <div class="col-md">
          {{ conteudo.item.dtacad }}
        </div>
        <div class="col-md-4 font-weight-bold">
          {{ conteudo.item.dscetrdoc }}
        </div>
        <div class="col-md-4">
          {{ conteudo.item.nomarq }}
        </div>
        <div class="col-md-2">
          <a 
            :href="criarLinkArquivo(conteudo.item)"
            target="_blank"
            class="btn btn-outline-light stretched-link"
          >
            <span class="sr-only">Clique para abrir</span>
            <em class="fas fa-external-link-alt" />
          </a>
        </div>
      </div>
    </template> 
  </PortalCardPaginado>
</template>
<script>
import PortalCardPaginado from '@/portal/components/destaque/PortalCardPaginado.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Actions, Getters, Mutations } from '../store/types';

export default {
    name: 'ProcessosCartaoBuscaArquivos',
    components: { PortalCardPaginado },
    props: {
        numpro: {
            type: String,
            required: true
        },
    },
    data() {
        return {
          busca: '',
          buscado: false,
          buscando: false,
          codetrcfc: '',
          codetrdoc: '',
          listaBusca: [],
          pagina: 1
        };
    },
    computed: {
        ...mapGetters({
            documentos: Getters.ANDAMENTOS.DOCUMENTOS,
            tiposConf: Getters.ESTRUTURA.TIPO_CONFERENCIA,
            tiposDoc: Getters.ESTRUTURA.TIPO_DOCUMENTO
        }),
        lista(){
          const self = this
          return self.documentos.filter(oco => {
              let aparecer = true
              if(self.codetrcfc){
                aparecer = aparecer && oco.codetrcfc === self.codetrcfc
              }
              if(self.codetrdoc){
                aparecer = aparecer && oco.codetrdoc === self.codetrdoc
              }
              return aparecer
          })
        }
    },
    methods: {
      ...mapActions({
        buscarArquivos: Actions.ANDAMENTOS.BUSCAR
      }), 
      ...mapMutations({
        limparArquivos: Mutations.ANDAMENTOS.LIMPAR_DOCUMENTOS
      }),
      buscar(){
        const self = this

        self.buscando = true
        self.buscarArquivos({
          numpro: this.numpro,
          busca: this.busca,
          codetrcfc: this.codetrcfc,
          codetrdoc: this.codetrdoc
        }).then(() => self.buscado = true)
        .finally(() => self.buscando = false)
      },
      limpar(){
        this.busca = ''
        this.buscado = false
        this.limparArquivos()
      },
      criarLinkArquivo(item){
        const baseUrl = `${window.location.origin}/proxy/wsprocesso/api`
        return `${baseUrl}/processo/${this.numpro}/anexo/${item.codarqpdl}?modoView=inline`
      }
    }
}
</script>

<style lang="scss" scoped>

  div.input-group:not(.has-validation) > .form-control:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  div.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  div.input-group > .input-group-append > .btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  span.label {
    font-weight: bold;
  }

  span.badge {
    padding: 8px;
  }

  div.ident {
    font-weight: bold;
    color: #1094ab
  }


  @media screen and (max-width: 768px) {
    div.linha-arvore {
      text-align: center;
    } 
  }

</style>