<template>
  <div class="form-group">
    <label 
      :for="inputId" 
      class="control-label"
    >
      {{ label }}
    </label>
    <usp-autocompletar 
      v-if="autocompletar"
      :id="inputId"
      v-model="sgleta"
      :name="inputName"
      :itens="lista"
      :erro-validacao="erroValidacao"
      :placeholder="placeholder"
      :obrigatorio="obrigatorio"
      @change="trocar"
    />
    <select
      v-else
      :id="inputId"
      v-model="sgleta"
      class="form-control"
      :name="inputName"
      :required="obrigatorio"
      @change="trocar"
    >
      <option value="">
        {{ placeholder }}
      </option>
      <option
        v-for="e in lista"
        :key="`${inputId}-${e.sgleta}`"
        :value="e.sgleta"
      >
        {{ e }}
      </option>
    </select>
  </div>
</template>

<script>
import { Getters } from '@/processos/store/types'
import { mapGetters } from 'vuex'
export default {

    name: 'EstacaoSelect',

    props: {
        autocompletar: {
          type: Boolean,
          default: true
        },
        erroValidacao: {
          type: String,
          default: ''
        },
        inputId: {
            type: String,
            default: 'sgleta'
        },
        inputName: {
            type: String,
            default: 'sgleta'
        },
        label: {
            type: String,
            default: 'Estação'
        },
        placeholder: {
            type: String,
            default: 'Escolha sua estação...'
        },
        obrigatorio: {
          type: Boolean,
          default: false
        },
        tipo: {
          type: String,
          default: 'UNIDADE',
          validator: (value) => {
            return ['UNIDADE', 'USUARIO', 'PESSOA'].includes(value);
          },
        }
    },

    data(){
        return {
            sgleta: ''
        }
    },

    computed: {
        ...mapGetters({
            estacoesUsuario: Getters.ESTRUTURA.ESTACOES_USUARIO,
            estacoesPessoa: Getters.ESTRUTURA.ESTACOES_PESSOA,
            estacoesUnidade: Getters.ESTRUTURA.ESTACOES_UNIDADE
        }),
        lista(){
          switch (this.tipo) {
            case 'USUARIO': return this.estacoesUsuario
            case 'PESSOA': return this.estacoesPessoa
            default: return this.estacoesUnidade
          }
        }
    },

    watch:{
        sgleta(sgleta){
            this.$emit('input', sgleta)
        }
    },

    methods: {
      trocar(val){
        this.$emit('change', val)
      }
    }

}
</script>