<template>
  <uspModal 
    titulo="Ajuda - Tipo de Conferência"
    :mostrar="mostrar"
    @fechar="fechar()"
  >
    <template #body>
      <p>A seguir, apresentamos orientações sobre cada tipo de conferência:</p>
      <ul>
        <li>
          <strong>Cópia Autenticada Administrativamente:</strong> cópias de documentos originais devidamente conferidas e validadas pelo agente público, ou seja, aquelas em que o usuário, dotado de fé pública, atesta a autenticidade de forma administrativa. O documento gerado, em PDF,
          pelo Serviço Documentos Digitais, é considerado uma cópia autenticada administrativamente, pois apresenta elementos (como o QR Code e o código de barras) que permitem que o destinatário confirme a sua autenticidade.
        </li>
        <li><strong>Cópia Autenticada por Cartório:</strong> documentos que possuem o selo cartorial, o que significa que foram autenticados em cartório por autoridade competente.</li>
        <li><strong>Cópia Simples:</strong> documentos advindos de fontes como editores de texto (como o Word), planilhas eletrônicas (como o Excel) e documentos simples recebidos por e-mail, sobre os quais não é possível verificar se se trata de documento original ou fotocópia, ou que não estão em papel timbrado, ou ainda que estejam assinados, porém, claramente, se trata de cópias.</li>
        <li><strong>Documento Original:</strong> documento original recebido pelo agente público e aferido por ele, independentemente se produzido em outro sistema eletrônico (assinado com certificado digital) ou fisicamente (em papel com assinatura).</li>
      </ul>
    </template>
    <template #footer>
      <uspBotao 
        type="button"
        variante="perigo"
        texto="Fechar"
        icone="fas fa-times"
        @clicar="fechar()"
      />
    </template>
  </uspModal>
</template>

<script>
export default {
    name: 'ProcessosModalAjudaTipoConf',
    props:{
        mostrar: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        fechar(){
            this.$emit('fechar')
        }
    },

}
</script>