<template>
  <section>
    <div class="container-fluid">
      <uspCartao titulo="Processos Digitais">
        <template #corpo>
          <div class="container-fluid usp-tarefas">
            <div class="form-row filtros abre-processo">
              <ProcessoInput 
                v-model="numpro"
                classe-div="form-group"
                input-id="busca-numpro"
              />
              <uspBotao 
                id="abrir-processo-direto"
                :texto="btnEntrar.texto"
                :icone="btnEntrar.icone"
                :class="btnEntrar.classe"
                @clicar="abrirProcesso"
              />
            </div>
            <uspAlerta
              v-if="erro"
              variante="perigo"
              :fechavel="true"
              @fechar="erro = ''"
            >
              {{ erro }}
            </uspAlerta>
            <form @submit.prevent="buscar()">
              <div class="row">
                <div class="form-group col-lg">
                  <label for="busca-busca">Termo de busca</label>
                  <input 
                    id="busca-busca"
                    v-model="busca"
                    type="text"
                    name="busca"
                    class="form-control"
                    placeholder="Busca por doc. base, interessado, assunto ou complemento"
                  >
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg">
                  <label for="busca-tipo">Situação</label>
                  <select 
                    id="busca-tipo" 
                    v-model="tipo"
                    name="tipo" 
                    class="form-control"
                  >
                    <option value="E">
                      Aguardando entrada / No seu setor
                    </option>
                    <option value="S">
                      Enviados (Aguardando entrada no setor destino)
                    </option>
                    <option value="A">
                      Arquivados
                    </option>
                  </select>
                </div>
                <div class="form-group col-lg">
                  <EstacaoSelect 
                    v-model="sgleta"
                    label="Setor"
                    :tipo="'USUARIO'"
                    placeholder="Todos"
                    :autocompletar="false"
                    input-id="busca-sgleta"
                  />
                </div>
                <div class="form-group col-lg">
                  <label for="busca-data">Movimentado até</label>
                  <input 
                    id="busca-data"
                    v-model="data"
                    type="date"
                    name="data"
                    class="form-control"
                  >
                </div>
                <div class="form-group col-lg switch">
                  <div class="custom-control custom-switch">
                    <input
                      id="tipvis-switch"
                      v-model="tipvis"
                      name="tipvis"
                      type="checkbox" 
                      class="custom-control-input"
                      value="P"
                    >
                    <label 
                      class="custom-control-label"
                      for="tipvis-switch"
                    >
                      Meus Processos
                    </label>
                  </div>
                </div>
              </div>
              <div class="buscar-acoes">
                <uspBotao
                  type="submit"
                  texto="Buscar"
                  icone="fas fa-search"
                />
                <router-link
                  :to="{ name: 'Processos:Novo' }"
                  class="btn btn-sm btn-success ml-3"
                >
                  <em class="fa fa-plus" />
                  Autuar Processo
                </router-link>
              </div>
            </form>
          </div>
        </template>
      </uspCartao>
      <PortalCardPaginado 
        titulo="Mesa Virtual"
        icone="fas fa-tasks"
        :lista="processosFiltrados"
        :por-pagina="porPagina"
        class="usp-tarefas portal-tarefas-cartao"
      >
        <template #filtros>
          <form>
            <div class="form-row">
              <label
                for="filtro-lista"
                class="sr-only"
              >
                {{ filtroLabel }}
              </label>
              <input
                id="filtro-lista"
                v-model="filtro"
                name="filtro"
                type="search"
                class="form-control form-control-sm"
                :placeholder="filtroLabel"
              >
              <small 
                id="filtro-lista-help"
                class="form-text text-muted"
              >
                {{ filtroTextoHelp }}
              </small>
            </div>
          </form>
        </template>
        <template #corpo>
          <div 
            v-if="!carregando && processos.length === 0"
            class="d-flex justify-content-center align-items-center flex-column aviso-vazio"
          >
            <img 
              src="/imgs/usp-tarefas.svg"
              alt="Ilustração de uma pilha de tarefas concluídas"
            >
            <p class="font-weight-bold text-center mt-5">
              &nbsp; Não há processos.
            </p>
          </div>
          <uspSkeleton v-if="carregando">
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
          </uspSkeleton>
        </template>
        <template 
          #default="conteudo"
        > 
          <div 
            v-show="!carregando && processos.length > 0"
            class="processo-item"
            :class="{ 'list-group-item-warning': conteudo.item.acao?.acao?.includes('Aguardando entrada') }"
          >
            <div class="titulo">
              <h2 class="h5">
                {{ conteudo.item.numprofmt }}
              </h2>
            </div>
            <div class="descricao">
              <span class="desc-item"><span class="label">Doc. Base: </span>{{ conteudo.item.titpro }}</span>
              <span class="desc-item"><span class="label">Interessado: </span>{{ conteudo.item.nominr }}</span>
              <span class="desc-item"><span class="label">Assunto: </span>{{ conteudo.item.assunto }}</span>
              <span class="desc-item"><span class="label">Complemento: </span>{{ conteudo.item.cplasu || 'Não há' }}</span>
            </div>
            <div class="datas">
              <span class="data">
                <em class="far fa-clock" />
                Abertura: {{ conteudo.item.abertura }}
              </span>
              <span 
                v-if="((conteudo.item.acao || {}).acao || '').indexOf('Aberto') === -1"
                class="data"
              >
                <em class="far fa-clock" />
                {{ conteudo.item.acao }}
              </span>
            </div>
            <div class="acoes">
              <router-link
                :to="{
                  name: 'Processos:Acompanhamento',
                  params: { numpro: conteudo.item.numpro },
                }"
                :class="['btn btn-sm', btnEntrar.classe]"
              >
                <em :class="btnEntrar.icone" />
                {{ btnEntrar.texto }}
              </router-link>
            </div>
          </div>
        </template>
      </PortalCardPaginado>
    </div>
  </section>
</template>

<script>

import { desformatarProcesso } from '@/utils/Formatador'
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '@/processos/store/types';
import PortalCardPaginado from "@/portal/components/destaque/PortalCardPaginado.vue";
import EstacaoSelect from '../components/inputs/EstacaoSelect.vue'
import ProcessoInput from '../components/inputs/ProcessoInput.vue';
export default {
    name: 'ProcessosInicio',

    components: { EstacaoSelect, ProcessoInput, PortalCardPaginado },

    data(){
      return {
        btnEntrar: {
          icone: 'fas fa-folder-open',
          classe: 'btn-info',
          texto: 'Abrir Processo'
        },
        filtro: '',
        filtroLabel: 'Filtrar processos por doc. base, interessado, assunto ou complemento',
        tipo: 'E',
        sgleta: '',
        busca: '',
        tipvis: false,
        data: new Date().toISOString().split("T")[0],
        erro: '',
        numpro: '',
        porPagina: 10
      }
    },

    computed:{
      ...mapGetters({
        carregando: Getters.PROCESSOS.CARREGANDO,
        processos: Getters.PROCESSOS.LISTA
      }),
      dataFormatada(){
        if(!this.data) return ''
        const partes = this.data.split('-')
        return `${partes[2]}/${partes[1]}/${partes[0]}`
      },
      filtroTextoHelp(){
        return `Mostrando ${this.processosFiltrados.length} processos do total de ${this.processos.length}`
      },
      processosFiltrados(){
        const self = this,
              filtro = self.filtro.toLowerCase()
        return this.filtro ? 
                 this.processos.filter(p => 
                    p.titpro.toLowerCase().indexOf(filtro) !== -1 || 
                    p.assunto.toString().toLowerCase().indexOf(filtro) !== -1 ||
                    p.nominr.toLowerCase().indexOf(filtro) !== -1 ||
                    p.cplasu.toLowerCase().indexOf(filtro) !== -1
                 ) : 
                 this.processos
      }
    },

    created(){
        this.carregarEstacoes();
        this.buscar()
    },

    methods: {
      ...mapActions({
        carregarEstacoes: Actions.ESTRUTURA.ESTACOES_USUARIO,
        listar: Actions.PROCESSOS.LISTAR
      }),
      abrirProcesso(){
        const self = this
        self.erro = ''
        if(!self.numpro){
          self.erro = 'Digite um número de processo válido antes de abri-lo.'
          return
        }
        self.$router.push(`/processos/processos-digitais/${desformatarProcesso(self.numpro)}`)
      },
      buscar(){
        const self = this;
        const dados = { 
          tipo: self.tipo,  
          data: self.dataFormatada,         
          tipvis: self.tipvis ? 'P' : 'S',
          busca: self.busca
        }
        if(self.sgleta){
          dados.sgleta = self.sgleta;
        }
        self.erro = ''
        self.listar(dados)
        .catch(error => self.erro = error)
      }
    },

}
</script>

<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

div.buscar-acoes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

div.switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1.5rem;
}

input[name="numpro"]{
  display: inline-block;
  margin-right: 1em;
  width: 12em
}

div.abre-processo.filtros {
  align-items: flex-start;
  justify-content: flex-start;
}

div.processo-item {
  align-items: center;
  flex-flow: row wrap;
  display: flex;
  border-bottom: 1px dotted #CECECE;
  padding: 0.8rem 1rem 0.2rem 0.8rem;

  div.titulo {
    color: #1094ab;
    width: 100%;  
  }

  div.descricao {
    flex: 2;
    display: flex;
    flex-flow: column nowrap;
  }

  span.label {
    color: #1094ab;
    font-weight: bold;
  }

  div.datas {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    span.data {
      text-align: left;
    }
  }

  div.acoes {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

}

@media (max-width: 992px) { 

  div.switch {
    justify-content: flex-end;
    padding-top: 0;
  }


}

@media (max-width: 768px) { 
    div.abre-processo {
      display: flex;
      flex-direction: column;
      > * {
        margin:0.3em 0;
        width: 100%;
      }
    }

    input[name="numpro"]{
      margin: 0;
      width: 100%
    }

    div.acoes {
      justify-content: center;
      padding: .7rem 0;
      button {
        width: 100%;
      }
    }

}



</style>