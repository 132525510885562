<template>
  <div :class="divClass">
    <label 
      :for="inputId"
      :class="label ? '' : 'sr-only'"
    >
      {{ label }}
    </label>
    <textarea
      :id="inputId"
      class="form-control"
      :placeholder="placeholder"
      :name="inputName"
      :value="value"
      :disabled="desabilitado"
      :style="styleTxtarea"
      @input="onInput" 
      @change="onChange"
    />
  </div>
</template>

<script>
export default {
    name: 'DespachoArea',
    props: {
        desabilitado: {
            type: Boolean,
            default: false
        },
        divClass: {
            type: String,
            default: 'form-group'
        },
        inputId: {
            type: String,
            default: 'txtdch'
        },
        inputName: {
            type: String,
            default: 'txtdch'
        },
        label: {
            type: String,
            default: 'Despacho'
        },
        placeholder: {
            type: String,
            default: 'Digite aqui o texto do despacho...'
        },
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            height: 40,
        }
    },
    computed: {
        quebralinhas(){
            // baseado em https://thewebdev.info/2022/05/19/how-to-count-the-number-of-lines-of-a-string-in-javascript/
            return this.value.split(/\r\n|\r|\n/).length
        },
        styleTxtarea(){
            return `resize: none; height: ${this.height + 25*(this.quebralinhas - 1)}px;`
        },
    },
    watch:{
        inicial(val){
            if(val) this.txtdch = val
        }
    },
    methods:{
        onChange(event){
            this.$emit('change', event.target.value);
        },
        onInput(event){
            this.$emit('input', event.target.value)
        }
    }

}
</script>
