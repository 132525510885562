<template>
  <div :class="['linha', colapsadoClasse]">
    <div 
      v-if="andamento?.novasOcorrencias.length > 0"
      class="rascunho alert alert-warning my-0 sticky-top"
    >
      <em class="fas fa-exclamation-circle mx-1 text-primary" />
      <strong class="text-primary">Atenção:</strong>
      Você possui uma ou mais ocorrências em modo rascunho, salve elas para não perder o seu trabalho!
    </div>
    <div class="conteudo">
      <div class="descricao">
        <a 
          class="collapser" 
          href="#" 
          @click.prevent="fechar"
        >
          <div class="identificador">
            <em :class="colapsadoIcone" />
            <span class="titulo">{{ andamento.numand }}.</span>
          </div>
          <div class="entrada">
            <div class="titulo">
              Entrada
            </div>
            <div class="subtitulo">
              {{ andamento.entrada.estacao.sgleta }} {{ andamento.entrada.dtaent }}
            </div>
          </div>
          <div 
            v-if="andamento.saida && andamento.saida.dtasai" 
            class="saida"
          >
            <div class="titulo">
              Saída
            </div>
            <div class="subtitulo">
              {{ andamento.saida.estacao.sgleta }} {{ andamento.saida.dtasai }}
            </div>
          </div>
          <div class="tempo">
            <div class="titulo">
              No Setor
            </div>
            <div class="subtitulo">
              {{ andamento.tmpeta }}
            </div>
          </div>
        </a>
      </div>
      <div 
        v-show="!colapsado"
        class="ocorrencias"
      >
        <ul class="list-group">
          <li 
            v-for="(oco, idx) in andamento.novasOcorrencias"
            :key="'nova_'+andamento.numand+'_'+idx"
            class="list-group-item ocorrencia-item"
          >
            <ProcessosOcorrenciaItem 
              :numpro="numpro"
              :ocorrencia="oco"
              :editavel="editavel"
              :indice="'*.'+(idx+1)" 
            />
          </li>
          <li 
            v-for="oco in listaOcorrencias"
            :key="andamento.numand+'_'+oco.indice"
            class="list-group-item ocorrencia-item"
          >
            <ProcessosOcorrenciaItem 
              :numpro="numpro"
              :ocorrencia="oco"
              :editavel="editavel"
              :indice="andamento.numand+'.'+oco.indice" 
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Andamento from '../dominio/modelos/Andamento';
import ProcessosOcorrenciaItem from './ProcessosOcorrenciaItem.vue';
export default {

    name: 'ProcessosAndamentoItem',

    components: { ProcessosOcorrenciaItem },

    beforeRouteLeave(to, from, next) {
      if (this.andamento.novasOcorrencias.length > 0){
        if (!window.confirm("Leave without saving?")) {
          return;
        }
      }
      next();
    },

    props: {
        andamento: {
            type: Andamento,
            required: true
        },
        editavel: {
            type: Boolean,
            default: false
        },
        numpro: {
          type: String,
          default: ''
        }
    },

    data(){
        return {
            // Se já saiu, traz fechado por padrão
            colapsado: this.andamento.saida?.dtasai
        }
    },

    computed: {
        colapsadoClasse(){
            return this.colapsado || this.vazio ? 'colapsado' : 'aberto'
        },
        colapsadoIcone() {
            return this.colapsado ? 'colapse fas fa-chevron-right' : 'colapse fas fa-chevron-down'
        },
        listaOcorrencias(){
            return this.andamento.ocorrencias.map((o, i) => {
                 o.indice = i+1
                 return o
            }).slice().reverse()
        },
        vazio(){
            return this.andamento.ocorrencias.length === 0 && this.andamento.novasOcorrencias.length === 0
        }
    },

    mounted(){
      const self = this;
      window.addEventListener('beforeunload', self.testarPreenchimento);
    },

    unmounted(){
      window.removeEventListener('beforeunload', this.testarPreenchimento);
    },

    methods:{
      testarPreenchimento(event){
        if (this.andamento.novasOcorrencias.length > 0){
            event.preventDefault()
            event.returnValue = ""
        }
      },
      fechar(){
          this.colapsado = !this.colapsado 
      }
    },

}
</script>

<style lang="scss" scoped>
    div.alert.rascunho {
      z-index: 1040;
      top: 143px;
    }

    .ocorrencia-item:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .linha, .conteudo {
        border-radius: inherit;
    }

    .linha.aberto {

        div.descricao {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    div.descricao {
        border-radius: inherit;
        padding: 0.25rem;
        margin-bottom: -1px;
        background-color: rgba(0,0,0,.3);
        border: 0;

        .titulo, .subtitulo {
            text-align: center;
        }
    }

    a.collapser {
        align-items: flex-start;
        display: flex;
        flex-flow: row nowrap;

        .subtitulo {
            font-size: 90% !important;
        }

        div.identificador {
            align-self: center;
            flex: 0 0 10%;
            padding-left: 0.2em;
        }

        div.entrada {
            flex: 0 0 30%;
        }

        div.saida {
            flex: 0 0 30%;
        }

        div.tempo {
            flex: 0 0 30%;
            margin-left: auto;
        }

    }

    .ocorrencias {

        ul.list-group {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }

    @media screen and (max-width: 560px) {
     
        li.ocorrencia-item {
             padding: 0.2em 0.5em !important;
        }
    }


</style>