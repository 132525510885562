<template>
  <div class="card minicard">
    <div class="card-header">
      <slot name="cabecalho">
        {{ titulo }}
      </slot>
    </div>
    <div class="card-body">
      <slot name="corpo" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProcessosMiniCartaoDados',
  props: {
    titulo: {
      type: String,
      default: ''
    }
  } 
}
</script>
<style scoped>

  div.minicard div.card-header {
    color: #1094ab;
    padding: 0.8rem;
  }

</style>