<template>
  <div class="form-group">
    <label :for="inputId">
      {{ label }}
    </label>
    <select 
      :id="inputId"
      v-model="codund"
      class="form-control"
      :name="inputName"
      :required="obrigatorio"
      @change="aoTrocar()"
    >
      <option value="">
        - Selecione -
      </option>
      <option 
        v-for="item in lista"
        :key="item.getCodigo()"
        :value="item.getCodigo()"
      >
        {{ item.getTexto() }}
      </option>
    </select>
    <span class="invalid-feedback">{{ erroValidacao }}</span>
  </div>
</template>

<script>
import { Actions, Getters } from '@/processos/store/types'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'UnidadeSelect',

    props: {
        erroValidacao: {
          type: String,
          default: ''
        },
        inputId: {
            type: String,
            default: 'codund'
        },
        inputName: {
            type: String,
            default: 'codund'
        },
        label: {
            type: String,
            default: 'Unidade'
        },
        obrigatorio: {
          type: Boolean,
          default: false
        }
    },

    data(){
        return {
            codund: '',
        }
    },

    computed: {
        ...mapGetters({
            lista: Getters.ESTRUTURA.UNIDADE
        })
    },

    mounted(){
        this.carregar()
    },

    methods:{
        ...mapActions({
            carregar: Actions.ESTRUTURA.UNIDADE,
            carregarEstacoes: Actions.ESTRUTURA.ESTACOES_UNIDADE
        }),
        aoTrocar(){
            const codund = this.codund
            this.$emit('input', codund)
            this.$emit('selecionar', codund)
            this.carregarEstacoes({ codund })
        }
    }

}
</script>