<template>
  <uspModal
    titulo="Desarquivamento de Processo"
    :mostrar="mostrar"
    @fechar="fechar()"
  >
    <template #body>
      <usp-alerta 
        v-if="erro"
        variante="perigo"
        :fechavel="true"
        @fechar="limparErro()"
      > 
        {{ erro }}
      </usp-alerta>
      <p>
        Deseja mesmo desarquivar o processo {{ numprofmt }}?
      </p>
    </template>
    <template #footer>
      <uspBotao 
        type="button"
        variante="sucesso"
        texto="Salvar"
        texto-carregando="Salvando"
        :carregando="salvando"
        icone="fas fa-save"
        @clicar="salvar()"
      />
      <uspBotao 
        type="button"
        variante="perigo"
        texto="Fechar"
        icone="fas fa-times"
        @clicar="fechar()"
      />
    </template>
  </uspModal>
</template>

<script>
import { formataProcesso } from '@/utils'
import { Actions } from '@/processos/store/types'
import { mapActions } from 'vuex'
export default {
    name: 'ProcessosDesarquivarEntrada',
    props:{
        mostrar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            numpro: this.$route.params.numpro,
            erro: '',
            salvando: false
        };
    },
    computed:{
        numprofmt(){
            return formataProcesso(this.numpro)
        }
    },
    methods: {
        ...mapActions({
          desarquivar: Actions.PROCESSOS.DESARQUIVAR,
        }),
        fechar(){
            this.$emit('fechar')
        },
        salvar(){
            const self = this;
            self.salvando = true
            self.desarquivar({ numpro: self.numpro })
                .then(() => self.fechar())
                .catch((error) => self.erro = error.erro)
                .finally(() => self.salvando = false)
            
        }
    },

}
</script>