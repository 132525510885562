<template>
  <form @submit.prevent="">
    <uspAlerta 
      v-if="aviso"
      :variante="aviso.tipo"
      fechavel
      @fechar="aviso = null"
    >
      {{ aviso.mensagem }}
    </uspAlerta>
    <div class="form-row justify-content-center">
      <ProcessosArquivoThumbnail 
        :temporario="ocorrencia.temporario"
        :arquivo="ocorrencia.arquivo" 
        :numpro="numpro"
      />
      <div class="col-lg">
        <div class="form-row">
          <div class="col-lg form-group">
            <label 
              for="codetrdoc"
              class="col-form-label"
            >
              Tipo de Documento
            </label>
            <uspAutocompletar 
              :id="'codetrdoc'"
              v-model="dados.codetrdoc"
              :name="'codetrdoc'"
              :desabilitado="!podeSalvar"
              :itens="tiposDoc"
              :inicial="dados.dscetrdoc"
              abre-no-foco
              placeholder="Escolha o tipo do documento"
            />
          </div>
          <div class="col-lg form-group">
            <label 
              for="codetrcfc"
              class="col-form-label"
            >
              Tipo de Conferência
              <a 
                href="#"
                @click.prevent="$emit('ajuda')"
              >
                <em class="fas fa-question-circle" />
              </a>
            </label>
            <select
              id="codetrcfc"
              v-model="dados.codetrcfc"
              name="codetrcfc"
              class="form-control"
              :disabled="!podeSalvar"
              required
            >
              <option value="">
                Selecione o tipo de conferência...
              </option>
              <option
                v-for="tipo in tiposConf"
                :key="tipo.codetrvlrpdl"
                :value="tipo.codetrvlrpdl"
              >
                {{ tipo.dscetrvlrpdl }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <DespachoArea 
            v-model="dados.txtdch"
            class="col-lg"
            label="Complemento"
            :desabilitado="!podeSalvar"
            placeholder="Digite aqui seu complemento"
            :inicial="dados.txtdch" 
          />
        </div>
        <div 
          v-if="ocorrencia.assinatura.sitasi"
          class="form-row assinatura"
        >
          <div class="col">
            <span class="titulo">Situação de Assinatura: </span>
            <span class="subtitulo">{{ ocorrencia.assinatura }}</span>
          </div>
        </div>
        <div 
          v-if="ocorrencia.assinatura.getIDDigital()"
          class="form-row assinatura"
        >
          <div class="col">
            <span class="titulo">Código de Autenticação: </span>
            <span class="subtitulo">{{ ocorrencia.assinatura.getIDDigital() }}</span>
            <a 
              :href="ocorrencia.assinatura.getUrlIDDigital()"
              target="_blank"
            >
              (Ver documento assinado)
            </a>
          </div>
        </div>
        <div class="form-row acoes">
          <router-link 
            v-if="podeVerLote"
            :to="{
              name: 'Assina:Lote',
              params: { codlotdocasi: ocorrencia.assinatura.codlotdocasi },
            }"
            class="btn btn-info btn-sm"
            tag="a"
          >
            <em class="fas fa-info-circle" /> 
            Ver Lote
          </router-link>
          <uspBotao 
            v-if="podeAssinar"
            type="button"
            tamanho="pq"
            variante="secundario"
            icone="fa fa-signature"
            texto="Solicitar Assinatura"
            @clicar="assinar(ocorrencia)"
          />
          <uspBotao 
            v-if="podeRemover"
            type="button"
            tamanho="pq"
            variante="perigo"
            icone="fa fa-trash"
            texto="Remover"
            @clicar="remover()"
          />
          <uspBotao 
            v-if="podeSalvar"
            type="button"
            tamanho="pq"
            variante="sucesso"
            icone="fa fa-save"
            texto="Salvar"
            texto-carregando="Salvando"
            :carregando="salvando"
            @clicar="salvar()"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { formatarTamanhoArquivo } from '@/utils/Formatador'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Actions, Getters, Mutations } from '@/processos/store/types'
import DespachoArea from '../inputs/DespachoArea.vue'
import ProcessosArquivoThumbnail from '../ProcessosArquivoThumbnail.vue'
import Ocorrencia from '@/processos/dominio/modelos/Ocorrencia'

export default {
    name: 'ProcessoOcorrenciaAnexoForm',
    components: { DespachoArea, ProcessosArquivoThumbnail },
    props: {
        indice: {
            type: String,
            required: true
        },
        ocorrencia: {
            type: Ocorrencia,
            required: true
        }
    },
    data() {
        return {
            aviso: null,
            numpro: this.$route.params.numpro,
            salvando: false,
            removendo: false,
            dados: {
                codetrdoc: this.ocorrencia.arquivo?.codetrdoc ?? '',
                dscetrdoc: this.ocorrencia.arquivo?.dscetrdoc ?? '',
                codetrcfc: this.ocorrencia.arquivo?.codetrcfc ?? '',
                txtdch: this.ocorrencia.txtdch || '',
            }
        };
    },
    computed: {
        ...mapGetters({
            tiposConf: Getters.ESTRUTURA.TIPO_CONFERENCIA,
            tiposDoc: Getters.ESTRUTURA.TIPO_DOCUMENTO
        }),
        podeAssinar(){
            return this.ocorrencia.podeEditar() && !this.ocorrencia.assinatura.sitlotdocasi && this.ocorrencia.numseqocoand && !this.ocorrencia.assinatura.sitasi
        },
        podeRemover(){
            return this.ocorrencia.podeEditar()
        },
        podeSalvar(){
            return this.ocorrencia.podeEditar()
        },
        podeVerLote(){
            return this.ocorrencia.podeEditar() && this.ocorrencia.assinatura.codlotdocasi
        },
        descricaoArquivo() {
            return this.anexo ? `${this.anexo.name} - ${formatarTamanhoArquivo(this.anexo.size)}` : this.descArq;
        },
        urlTemporario(){
            return !this.codarqtmp ? '' : `/proxy/wsprocesso/api/arquivo/temp/${this.codarqtmp}/preview`
        }
    },
    methods:{
        ...mapActions({
            removerNovaOcorrencia: Actions.ANDAMENTOS.REMOVER_NOVA_OCORRENCIA,
            removerOcorrencia: Actions.ANDAMENTOS.REMOVER_OCORRENCIA,
            salvarOcorrencia: Actions.ANDAMENTOS.SALVAR_OCORRENCIA
        }),
        ...mapMutations({
            assinar: Mutations.ANDAMENTOS.ASSINATURA,
            setConfirmar: Mutations.PROCESSOS.CONFIRMAR
        }),
        remover(){
            this.setConfirmar({
                titulo: 'Remoção do anexo #'+this.indice,
                mensagem: `Deseja mesmo remover o anexo #${this.indice}?`,
                atencao: true,
                abrir: true,
                acao: this.ocorrencia.numseqocoand ? this.removerOcorrencia : this.removerNovaOcorrencia,
                dados: this.ocorrencia,
            })
        },
        salvar(){
            const self = this
            self.salvando = true

            self.salvarOcorrencia({ numpro: self.numpro, ocorrencia: self.ocorrencia, dados: self.dados })
                .catch(error => self.aviso = { mensagem: error.erro, tipo: 'perigo' })
                .then(retorno => self.aviso = { mensagem: retorno.mensagem, tipo: 'sucesso' })
                .finally(() => self.salvando = false)
        },
    }
}
</script>

<style lang="scss" scoped>

    form {
        div.assinantes {
            align-self: flex-end;

            button {
                margin-left: 0.5em;
            }

        }
        div.assinatura {
            padding: .5em 0;
        }
        div.nome-arquivo {
            align-items: center;

            span.titulo {
                font-weight: bold !important;
                margin-right: 2px;
            }
            span.subtitulo {
                flex: 1;
            }
        }

        div.acoes {
            margin: 1em 0;
            justify-content: flex-end;
        }
    }
</style>