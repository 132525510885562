<template>
  <form @submit.prevent="">
    <uspAlerta 
      v-if="aviso"
      :variante="aviso.tipo"
      fechavel
      @fechar="aviso = null"
    >
      {{ aviso.mensagem }}
    </uspAlerta>
    <DespachoArea 
      v-model="dados.txtdch"
      div_class="form-row form-group"
      label="Informação"
      placeholder="Digite aqui a sua informação"
      :inicial="dados.txtdch" 
    />
    <div
      v-if="ocorrencia.podeEditar()"
      class="form-row acoes"
    >
      <uspBotao 
        type="button"
        tamanho="pq"
        variante="perigo"
        icone="fa fa-trash"
        texto="Remover"
        @clicar="confirmarRemocao()"
      />
      <uspBotao
        type="button"
        tamanho="pq"
        variante="sucesso"
        icone="fa fa-save"
        texto="Salvar"
        texto-carregando="Salvando"
        :carregando="salvando"
        @clicar="salvar()"
      />
    </div>
  </form>
</template>

<script>
import Ocorrencia from '@/processos/dominio/modelos/Ocorrencia';
import DespachoArea from '../inputs/DespachoArea.vue';
import { mapActions, mapMutations } from 'vuex';
import { Actions, Mutations } from '@/processos/store/types';
export default {
    name: 'ProcessoOcorrenciaInformacaoForm',
    components: { DespachoArea },
    props: {
        indice: {
            type: String,
            required: true
        },
        ocorrencia: {
            type: Ocorrencia,
            required: true
        },
        numpro: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            aviso: null,
            salvando: false,
            dados: {
                txtdch: (this.ocorrencia || {}).txtdch || ''
            }
        }
    },
    methods: {
        ...mapActions({
            removerNovaOcorrencia: Actions.ANDAMENTOS.REMOVER_NOVA_OCORRENCIA,
            removerOcorrencia: Actions.ANDAMENTOS.REMOVER_OCORRENCIA,
            salvarOcorrencia: Actions.ANDAMENTOS.SALVAR_OCORRENCIA,
        }),
        ...mapMutations({
            setConfirmar: Mutations.PROCESSOS.CONFIRMAR
        }),
        confirmarRemocao(){
          this.setConfirmar({
              titulo: 'Remoção da informação #'+this.indice,
              mensagem: `Deseja mesmo remover a informação #${this.indice}?`,
              atencao: true,
              abrir: true,
              acao: this.ocorrencia.numseqocoand ? this.removerOcorrencia : this.removerNovaOcorrencia,
              dados: this.ocorrencia,
          })
        },
        salvar(){
            const self = this
            if(!self.dados.txtdch){
              self.aviso = {
                mensagem: 'O campo de informação é obrigatório!', 
                tipo: 'perigo'
              }
              return
            }
            if(self.dados.txtdch == self.ocorrencia.txtdch){
              self.aviso = {
                mensagem: 'Nada foi alterado!', 
                tipo: 'aviso'
              }
              return
            }
            self.salvando = true
            self.salvarOcorrencia({ numpro: self.numpro, ocorrencia: self.ocorrencia, dados: self.dados })
                .catch(error => self.aviso = { mensagem: error.erro, tipo: 'perigo' })
                .then(retorno => self.aviso = { mensagem: retorno.mensagem, tipo: 'sucesso' })
                .finally(() => self.salvando = false)
        }
    }
}
</script>

<style lang="scss" scoped>

    form {
        div.acoes {
            margin: 1em 0;
            justify-content: flex-end;
        }
    }

</style>