<template>
  <uspCartao 
    :fechavel="false"
    :titulo="titulo"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div
        v-else
        class="card-container"
      >
        <ProcessosMiniCartaoDados>
          <template #cabecalho>
            <span />
            {{ processo.titpro }}
            <uspBadge
              :texto="processo.situacao"
              class="situacao"
              :variante="varianteSituacao"
            />
          </template>
          <template #corpo>
            <div class="row">
              <div class="col-md">
                <div class="label">
                  Interessado
                </div>
                <div class="text">
                  {{ processo.nominr }}
                </div>
              </div>
              <div class="col-md">
                <div class="label">
                  Assunto
                </div>
                <div class="text">
                  {{ processo.assunto }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <div class="label">
                  Complemento
                </div>
                <div 
                  :class="['text', cplasuClass]"
                  :title="processo.cplasu"
                >
                  {{ processo.cplasu }}
                </div>
              </div>
            </div>
            <div class="row mostrar-mais">
              <a 
                href="#"
                :class="['col-md', mostrarMaisEstilo]"
                @click.prevent="mostrarMais = !mostrarMais"
              >
                {{ mostrarMaisTexto }}
              </a>
            </div>
          </template>
        </ProcessosMiniCartaoDados>
        <ProcessosMiniCartaoDados
          titulo="Abertura"
        >
          <template #corpo>
            <div class="row">
              <div class="col-md">
                <div class="label">
                  Aberto em
                </div>
                <div class="text">
                  {{ processo.abertura?.dtaabt }}
                </div>
              </div>
              <div class="col-md">
                <div class="label">
                  Unidade
                </div> 
                <div class="text">
                  {{ processo.abertura?.unidade }}
                </div>
              </div>
              <div class="col-md">
                <div class="label">
                  Estação
                </div>
                <div class="text">
                  {{ processo.abertura?.estacao?.sgleta }} - {{ processo.abertura?.estacao?.nometa }}
                </div>
              </div>
            </div>
          </template>
        </ProcessosMiniCartaoDados>
        <ProcessosMiniCartaoDados
          titulo="Atual"
        >
          <template #corpo>
            <div class="row">
              <div class="col-md">
                <div class="label">
                  Unidade
                </div> 
                <div class="text">
                  {{ processo.estacaoAtual.unidade }}
                </div>
              </div>
              <div class="col-md">
                <div class="label">
                  Estação
                </div>
                <div class="text">
                  {{ processo.estacaoAtual.sgleta }} - {{ processo.estacaoAtual.nometa }}
                </div>
              </div>
            </div>
          </template>
        </ProcessosMiniCartaoDados>
      </div>
    </template>
  </uspCartao>
</template>
<script>
import { formataProcesso } from '@/utils/Formatador'
import { mapActions, mapGetters } from 'vuex'
import { Actions, Getters } from '@/processos/store/types'
import ProcessosMiniCartaoDados from './cartoes/ProcessosMiniCartaoDados.vue'
export default {
    name: 'ProcessosCartaoDados',
    components: { ProcessosMiniCartaoDados },
    props: {
        numpro: {
            type: String,
            required: true
        },
    },
    data() {
      return {
        baseUrl: window.location.origin,
        mostrarMais: false,
      };
    },
    computed: {
        ...mapGetters({
            carregando: Getters.PROCESSOS.OBTENDO_DADOS,
            processo: Getters.PROCESSOS.DADOS
        }),
        cplasuClass() {
          return this.mostrarMais ? 'complemento-aberto' : 'complemento-fechado text-truncate'
        },
        mostrarMaisEstilo(){
          return this.mostrarMais ? 'text-secondary' : 'text-primary'
        },
        mostrarMaisTexto(){
          return this.mostrarMais ? '(mostrar menos)' : '(mostrar-mais)'
        },
        numprofmt() {
            return formataProcesso(this.numpro);
        },
        titulo() {
            return `Processo Digital ${this.numprofmt ? this.numprofmt : ""}`;
        },
        varianteSituacao(){
          if(this.processo.situacao === 'Arquivado') return 'claro'
          if(this.processo.situacao === 'Juntado') return 'sucesso'
          else return 'secundario'
        }
    },
    mounted() {
        this.carregar({ numpro: this.numpro });
    },
    methods: {
        ...mapActions({
            carregar: Actions.PROCESSOS.CARREGAR_DADOS
        })
    },
}
</script>

<style lang="scss" scoped>

  div.complemento-fechado {
    white-space: nowrap;
  }

  div.complemento-aberto {
    white-space: break-spaces;
  }

  div.mostrar-mais {
    text-align: right;
  }

  .card-container {
    
    box-shadow: 0 5px 20px 0 rgba(69, 67, 96, 0.05);

    ::v-deep .card:first-of-type {
      border-top-left-radius: 1.1rem;
      border-top-right-radius: 1.1rem;

      .card-header {
        border-top-left-radius: 1.1rem;
        border-top-right-radius: 1.1rem;
      }

    }

    ::v-deep .card:last-of-type {
      border-bottom-left-radius: 1.1rem;
      border-bottom-right-radius: 1.1rem;
    }

    ::v-deep .card {

      box-shadow: none;
      border: 0.1em solid rgba(0, 0, 0, 0.125) !important;
      margin: 0;
      margin-bottom: -0.1em;
      border-radius: 0;

      .card-header {
        background-color:rgba(0, 0, 0, 0.125);
        padding: 0.8rem;
        color: #1094ab;
        justify-content: center;

        *:first-child {
          border-left: 0;
          padding-left: initial;
        }

        .situacao {
          font-size: 100%;
          margin: 0 .3em;
        }
      }

      .card-body {
        border-bottom: 0.0625rem dotted #f5f5f5;
        border-top: 0.0625rem dotted #f5f5f5;
      }

    }

  }
    strong.label, div.label {
        color: #1094ab;
        font-weight: bolder;
    }

    a.dropdown-item {
        align-items: center;
        display: flex;

        i.fas {
            flex: 0 0 1rem;
            margin-right: 0.3em;
        }
    }

</style>