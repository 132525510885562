<template>
  <uspModal
    :titulo="titulo"
    :mostrar="mostrar"
    @fechar="fechar()"
  >
    <template #body>
      <usp-alerta 
        v-if="erro"
        variante="perigo"
        :fechavel="true"
        @fechar="limparErro()"
      > 
        {{ erro }}
      </usp-alerta>
      <p class="mensagem">
        {{ confirmar.mensagem }}
      </p>
      <p
        v-show="confirmar.atencao"
        class="atencao"
      >
        <strong>atenção</strong>: essa ação não pode ser desfeita!
      </p>
    </template>
    <template #footer>
      <uspBotao 
        type="button"
        variante="sucesso"
        texto="Salvar"
        texto-carregando="Salvando"
        :carregando="salvando"
        icone="fas fa-save"
        @clicar="salvar()"
      />
      <uspBotao 
        type="button"
        variante="perigo"
        texto="Fechar"
        icone="fas fa-times"
        @clicar="fechar()"
      />
    </template>
  </uspModal>
</template>

<script>
import { Getters, Mutations } from '@/processos/store/types'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'ProcessosModalConfirmar',
    computed:{
      ...mapGetters({
        confirmar: [Getters.PROCESSOS.CONFIRMAR],
        erro: [Getters.PROCESSOS.CONFIRMAR_ERRO],
        salvando: [Getters.PROCESSOS.CONFIRMAR_SALVANDO]
      }),
      mostrar(){
        return this.confirmar.abrir
      },
      titulo(){
        return this.confirmar.titulo || ''
      },
    },
    methods: {
      ...mapMutations({
        setConfirmar: Mutations.PROCESSOS.CONFIRMAR,
        setConfirmarErro: Mutations.PROCESSOS.CONFIRMAR_ERRO,
        setConfirmarSalvando: Mutations.PROCESSOS.CONFIRMAR_SALVANDO
      }),
      fechar(){
        this.setConfirmar({})
        this.setConfirmarErro('')
        this.setConfirmarSalvando(false)
      },
      limparErro(){

      },
      salvar(){
        const self = this
        self.confirmar.acao(self.confirmar.dados)
      }
    }
}
</script>

<style lang="scss" scoped>

    p.atencao {
      strong {
        text-transform: uppercase;
      }
    }

</style>