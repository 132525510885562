import { PortalServico } from '@/utils/';

/**
 * @type {ComumServico}
 */
export default class ComumServico extends PortalServico {

    obterPessoa(dados){
        return this.post(`/uspproc/obter/marteweb/owcObterPESSOA`, dados)
    }

}