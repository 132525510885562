<template>
  <div 
    :id="ocorrencia.getKey()"
    :class="{ 'ocorrencia-item': true, 'modo-rascunho': isModoRascunho(ocorrencia) }"
  >
    <div class="cabecalho">
      <div class="titulo indice">
        {{ indice || '*.*' }}
      </div>
      <div class="nome">
        <span 
          v-if="isModoRascunho(ocorrencia)"
          class="titulo"
        >
          <em class="fas fa-exclamation-circle" />
          <span>Modo Rascunho</span>
          <em class="fas fa-exclamation-circle" />
        </span>
        <span 
          v-else
          class="titulo"
        >
          {{ ocorrencia.dtacad }}
        </span>
        <span class="titulo">{{ ocorrencia.nompescad }} ({{ ocorrencia.codpescad }})</span>
      </div>
      <div class="titulo tipo">
        {{ ocorrencia.tipo }}
      </div>
    </div>
    <uspAlerta 
      v-if="alerta"
      style="width: 100%"
      :variante="alerta.variante"
      fechavel
      @fechar="alerta = null"
    > 
      {{ alerta.mensagem }}
    </uspAlerta>
    <ProcessoOcorrenciaDisplayForm 
      v-if="!editavel || !ocorrencia.podeEditar()"
      :ocorrencia="ocorrencia" 
      :numpro="numpro"
    />
    <ProcessosOcorrenciaGerarForm 
      v-if="editavel && ocorrencia.tipo.codetroco === 'ocoGeracao'"
      :ocorrencia="ocorrencia" 
      :indice="indice"
      :numpro="numpro"
    />
    <ProcessosOcorrenciaInformacaoForm 
      v-if="editavel && ocorrencia.tipo.codetroco === 'ocoInformacao'"
      :numpro="numpro"
      :ocorrencia="ocorrencia"
      :indice="indice" 
    />
    <ProcessosOcorrenciaAnexoForm 
      v-if="editavel && ocorrencia.tipo.codetroco === 'ocoAnexacao'"
      :ocorrencia="ocorrencia"
      :indice="indice"
      :numpro="numpro"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { Actions, Mutations } from '@/processos/store/types'
import Ocorrencia from '@/processos/dominio/modelos/Ocorrencia'
import ProcessosOcorrenciaAnexoForm from '@/processos/components/forms/ProcessosOcorrenciaAnexoForm.vue'
import ProcessosOcorrenciaGerarForm from '@/processos/components/forms/ProcessosOcorrenciaGerarForm.vue'
import ProcessosOcorrenciaInformacaoForm from '@/processos/components/forms/ProcessosOcorrenciaInformacaoForm.vue'
import ProcessoOcorrenciaDisplayForm from './forms/ProcessoOcorrenciaDisplayForm.vue'
export default {

    name: 'ProcessosOcorrenciaItem',

    components: { 
        ProcessosOcorrenciaAnexoForm, 
        ProcessosOcorrenciaGerarForm,
        ProcessosOcorrenciaInformacaoForm, 
        ProcessoOcorrenciaDisplayForm, 
    },

    props: {
        editavel: {
            type: Boolean,
            default: false,
        },
        indice: {
            type: String,
            default: ''
        },
        ocorrencia: {
            type: Ocorrencia,
            required: true
        },
        numpro: {
            type: String,
            default: ''
        }
    },

    data(){
        return {
            alerta: undefined,
            gerando: false,
            removendo: false,
            salvando: false,
            documento: {}
        }
    },

    methods: {
        ...mapActions({
            gerarAnexo: Actions.ANDAMENTOS.GERAR_ANEXO,
            removerOcorrencia: Actions.ANDAMENTOS.REMOVER_OCORRENCIA,
            salvarOcorrencia: Actions.ANDAMENTOS.SALVAR_OCORRENCIA
        }),
        ...mapMutations({
            setConfirmar: Mutations.ANDAMENTOS.CONFIRMAR
        }),
        confirmarRemocao(){
            const confirmar = {}

             if(this.ocorrencia.tipo.codetroco === 'ocoAnexacao'){
                confirmar.titulo = 'Remoção do anexo #'+this.indice
                confirmar.mensagem = `Deseja mesmo remover o anexo #${this.indice}: ${this.ocorrencia.getDescricaoArquivo()}?`
                confirmar.atencao = true
                confirmar.abrir = true
                confirmar.acao = this.removerOcorrencia
                confirmar.ocorrencia = this.ocorrencia
            } else if(this.ocorrencia.tipo.codetroco === 'ocoGeracao'){
                confirmar.titulo = 'Remoção da geração de documento #'+this.indice
                confirmar.mensagem = `Deseja mesmo remover a geração de documentos #${this.indice}?`
                confirmar.atencao = true
                confirmar.abrir = true
                confirmar.acao = this.removerOcorrencia
                confirmar.ocorrencia = this.ocorrencia
            }
            this.setConfirmar(confirmar)
        },
        gerar(){
            const self = this

            self.gerarAnexo({ ...self.documento, salvar: 'N' })
                .then(pdf => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([pdf]))
                    link.setAttribute('download', 'preview.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
        },
        isModoRascunho(ocorrencia){
            return !ocorrencia.dtacad
        },
        salvar(){
            const self = this
            
            self.salvando = true

            if(self.ocorrencia.tipo.codetroco === 'ocoGeracao'){
                self.gerarAnexo({ ...self.documento, salvar: 'S' }, self.ocorrencia)
                return
            }

            self.salvarOcorrencia({ 
                ocorrencia: self.ocorrencia, 
                dados: self.dados
            })
            .then((retorno) => {
                self.alerta = { mensagem: retorno.mensagem, variante: 'sucesso' }
            }).catch((error) => {
                self.alerta = { mensagem: error.erro || error, variante: 'perigo' }
            }).finally(() => {
                self.salvando = false
            })
            
        }
    },

}
</script>

<style scoped lang="scss">

    div.ocorrencia-item {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        border: 0.75px solid rgba(0, 0, 0, .10);
        overflow: hidden;

        &.modo-rascunho {
            background-color: #ffffff;
            opacity: 0.8;
            background-size: 10px 10px;
            background-image: repeating-linear-gradient(45deg, #ffeeba 0, #ffeeba 1px, #ffffff 0, #ffffff 50%);
            box-shadow:
                0px 0px 10px rgba(0, 0, 0, 0.029),
                0px 0px 80px rgba(0, 0, 0, 0.07)
                ;
        }   

        div.cabecalho {
            align-items: center;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, .10);
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
        
            .indice {
                display: inline-block;
                flex: 0 0 2em;
            }
            .nome {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;

                .titulo {
                    text-align: center;
                    margin: 0 .1em;

                    > * {
                        margin-left: .4em;
                        margin-right: .4em;
                    }
                }
            }

            :first-child {
                margin-left: .4em
            }

            :last-child {
                margin-right: .4em
            }
        }

    }

    ::v-deep form {
        padding: 0.5em 1.75em;
        flex-basis: 100% !important;
    }

    @media screen and (max-width: 560px) {
     
        ::v-deep form {
            padding: 0.2em 0.5em;
        }

    }

    div.acoes {
        display: flex;
        flex-flow: row-reverse nowrap;
        margin-left: auto;
        justify-content: center;
    }

    ::v-deep label, span.titulo {
        font-weight: bold;
        color: #1094ab
    }

</style>