<template>
  <div :class="classeDiv">
    <label
      :for="inputId" 
      :class="classeLabel"
    >
      {{ label }}
    </label>
    <input
      :id="inputId"
      v-model="numero"
      :placeholder="inputPlaceholder"
      :name="inputName"
      :class="classeInput"
      type="text"
      @change="$emit('change')"
    >
    <div class="ajuda">
      <em class="fas fa-question-circle" />
      <span class="ajudatexto">
        Digite o número formatado completo (ex 2021.1.2345.1.2) ou sem pontos e com todos os zeros (ex 23900004927).
      </span>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'ProcessoInput',
    props: {
      classeDiv: {
        type: String,
        default: 'form-group'
      },
      classeLabel: {
        type: String,
        default: 'sr-only'
      },
      classeInput: {
        type: String,
        default: 'form-control'
      },
      label: {
        type: String,
        default: 'Número Processo'
      },
      inputId: {
        type: String,
        required: true
      },
      inputName: {
        type: String,
        default: "numpro"
      },
      inputPlaceholder: {
        type: String,
        default: 'Digite o número...'
      }
    },

    data(){
      return {

      }
    },

    computed:{
      tipo(){
        return this.numero[3]
      },
      numero: {
        get(){
          return this.$attrs.value
        },
        set(numero){
          this.$emit('input', numero)
        }
      },
    },

  }
</script>
<style lang="scss" scoped>
  input {
    display: inline-block;
    margin-right: 1em;
    width: 12em;
  }

  .ajuda {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;

    .ajudatexto {
      visibility: hidden;
      width: 450px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: .75em;
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 1s;
    }
  }

  .ajuda:hover .ajudatexto {
    visibility: visible;
    opacity: 1;
  } 
</style>